import { Currency } from "../checkout/types/checkoutTypes"
import API from "../server/serverInterface"
import { CartProductWithQuantity, CartProduct } from "../ts/interfaces"
import { getCookie } from "./klaviyo"
import { getLs, setLs } from "./localStorage"
import { getFbc } from "./queryParams"

declare global {
  interface Window {
    gaGlobal: { vid: string } | undefined
  }
}

let queuedGEvents: { eventName: string; data: any }[] = []
let queuedPEvents: { eventType: string; fbqObj: any; eventID: string }[] = []

const generateNewID = (length: number): string => {
  if (length < 1) {
    throw new RangeError("Length must be at least 1")
  }
  const possible = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  let string = ""
  for (let i = 0; i < length; i += 1) {
    string += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return string
}

export type PixelEventType =
  | "Lead"
  | "AddToCart"
  | "Purchase"
  | "InitiateCheckout"
  | "ViewContent"
  | "add_shipping_info"
  | "view_cart"

type EventCartData = {
  items: CartProductWithQuantity[]
  total: number
  currency: Currency
  confirmationCode?: string
}

const buildPixelEventDataPurchaseIntiiate = (data: EventCartData) => {
  const fbqObj = {
    content_type: "product",
    num_items: data.items.length,
    content_name: data.items[0].name,
    content_ids: [...data.items.map(product => product?.id)],
    contents: [
      ...data.items.map(product => {
        const price =
          (product && product.prices && product.prices[data.currency]) || null
        return {
          id: product?.id,
          name: product?.name,
          quantity: product?.quantity,
          price: price ? parseFloat((price.unit_amount / 100.0).toFixed(2)) : 0,
        }
      }),
    ],
    value: parseFloat((data.total / 100.0).toFixed(2)),
    currency: data.currency,
  }
  return fbqObj
}

const buildPixelEventDataAddToCartViewContent = (data: EventCartData) => {
  const cartProduct = data.items[0]

  const price =
    (cartProduct && cartProduct.prices && cartProduct.prices[data.currency]) ||
    null

  const fbqObj = {
    content_type: "product",
    content_ids: [cartProduct.id],
    content_name: cartProduct.name,
    content_category: "headset",
    contents: [
      {
        id: cartProduct?.id,
        name: cartProduct?.name,
        quantity: cartProduct?.quantity,
      },
    ],
    ...(price && {
      value: parseFloat((price.unit_amount / 100.0).toFixed(2)),
    }),
    currency: data.currency,
  }
  return fbqObj
}

const buildGtagEventDataAddToCartViewContent = (data: EventCartData) => {
  const cartProduct = data.items[0]

  const price =
    (cartProduct && cartProduct.prices && cartProduct.prices[data.currency]) ||
    null

  const gObj = {
    items: [
      {
        item_id: cartProduct?.id,
        item_name: cartProduct?.name,
        quantity: cartProduct?.quantity,
        price: price ? parseFloat((price.unit_amount / 100.0).toFixed(2)) : 0,
      },
    ],
    ...(price && {
      value: parseFloat((price.unit_amount / 100.0).toFixed(2)),
    }),
    currency: data.currency.toUpperCase(),
  }
  return gObj
}

const buildGtagEventDataPurchaseInitiate = (data: EventCartData) => {
  const gObj = {
    items: [
      ...data.items.map(product => {
        const price =
          (product && product.prices && product.prices[data.currency]) || null
        if (!price) {
          return null
        }
        return {
          item_id: product?.id,
          item_name: product?.name,
          quantity: product?.quantity,
          price: parseFloat((price.unit_amount / 100.0).toFixed(2)),
        }
      }),
    ],
    ...(data.confirmationCode && { transaction_id: data.confirmationCode }),
    value: parseFloat((data.total / 100.0).toFixed(2)),
    currency: data.currency.toUpperCase(),
  }
  return gObj
}

const buildPixelEventData = (
  eventType: PixelEventType,
  data?: EventCartData,
  personaId?: string
) => {
  if (eventType === "Lead") {
    return {
      content_category: "email",
      content_name: personaId || "none",
    }
  }
  if (!data || data.items.length <= 0) {
    console.log("No items provided to pixel")
    return null
  }

  if (eventType === "AddToCart" || eventType === "ViewContent") {
    return buildPixelEventDataAddToCartViewContent(data)
  }
  return buildPixelEventDataPurchaseIntiiate(data)
}
const eventMap = {
  Lead: "generate_lead",
  AddToCart: "add_to_cart",
  Purchase: "purchase",
  InitiateCheckout: "begin_checkout",
  ViewContent: "view_item",
  add_shipping_info: "add_shipping_info",
  view_cart: "view_cart",
}

const sendGtagEventData = (
  eventType: PixelEventType,
  id: string,
  data?: EventCartData
) => {
  if (eventType === "Lead") {
    queueOrSendGoogleEvent(eventMap[eventType])
    return
  }
  if (!data || data.items.length <= 0) {
    console.log("No items provided to pixel")
    return
  }
  if (typeof window === `undefined` || !window.gtag) {
    return
  }

  const eventData =
    eventType === "AddToCart" || eventType === "ViewContent"
      ? buildGtagEventDataAddToCartViewContent(data)
      : buildGtagEventDataPurchaseInitiate(data)

  queueOrSendGoogleEvent(eventMap[eventType], {
    ...eventData,
    transaction_id: id,
  })
}

export const sendQueuedEvents = () => {
  const tempGEvents = [...queuedGEvents]
  queuedGEvents = []
  tempGEvents.forEach(e => {
    queueOrSendGoogleEvent(e.eventName, e.data)
  })

  const tempPEvents = [...queuedPEvents]
  queuedPEvents = []
  tempPEvents.forEach(e => {
    queueOrSendPixelEvent(e.eventType, e.fbqObj, e.eventID)
  })
}

export const queueOrSendGoogleEvent = (eventName: string, data?: any) => {
  if (typeof window === `undefined` || !window.trackingInit || !window.gtag) {
    //queue the event
    queuedGEvents.push({ eventName, data })
    return
  }
  if (data) {
    window.gtag("event", eventName, data)
  } else {
    window.gtag("event", eventName)
  }
}

export const queueOrSendPixelEvent = (
  eventType: string,
  fbqObj: any,
  eventID: string
) => {
  if (typeof window === `undefined` || !window.trackingInit || !window.fbq) {
    //queue the event
    queuedPEvents.push({ eventType, fbqObj, eventID })
    return
  }

  window.fbq("track", eventType, fbqObj, { eventID })
}

export const sendPixelEvent = async (
  eventType: PixelEventType,
  sendPxEvent: Function,
  data?: EventCartData,
  email?: string,
  personaId?: string,
  firstName?: string,
  lastName?: string
) => {
  const id = data?.confirmationCode ?? `${Date.now()}-${generateNewID(8)}`

  let fbqObj
  if (eventType !== "add_shipping_info" && eventType !== "view_cart") {
    fbqObj = buildPixelEventData(eventType, data, personaId)

    if (!fbqObj) {
      return
    }
    if (typeof window !== `undefined` && window.fbq) {
      queueOrSendPixelEvent(eventType, fbqObj, id)
    }
  }

  sendGtagEventData(eventType, id, data)

  if (eventType === "add_shipping_info" || eventType === "view_cart") {
    return
  }

  let fbp = getCookie("_fbp")
  let fbc = getCookie("_fbc")
  if (!fbc) {
    fbc = getFbc()
  }
  if (fbc?.includes("?fbclid=")) {
    fbc = fbc.split("?fbclid=")[0]
  }
  const user: any = {}
  if (fbc) {
    user.fbc = fbc
  }
  if (fbp) {
    user.fbp = fbp
  }
  if (email) {
    user.email = email
  }
  if (firstName) {
    user.firstName = firstName
  }
  if (lastName) {
    user.lastName = lastName
  }
  const client = API.getClientDef("website")
  if (!client) {
    throw Error("Client not found")
  }
  const queryDef = client.getDef("sendPixelEvent")
  if (!queryDef) {
    throw Error("Api not found")
  }
  if (queryDef.type !== "query") {
    throw Error("Api is not a query")
  }

  const params = {
    variables: {
      pixelEvent: {
        event_id: id,
        eventType,
        ...fbqObj,
        user,
        client_id: getGAClientId(),
      },
    },
  }

  await sendPxEvent(params)
}

const getGAClientId = (): string => {
  if (window !== undefined && window.gaGlobal?.vid) {
    // Cookies have been accepted, get the client_id from GA4
    return window.gaGlobal.vid
  } else {
    // Cookies have not been accepted, generate a random client_id
    const getRandomStringDigits = () =>
      Math.floor(Math.random() * 1000000000).toString()

    return `${getRandomStringDigits()}.${getRandomStringDigits()}`
  }
}
